import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSubscriptionCheck } from './useSubscriptionCheck';

function CheckSubscription() {
  const { hasSubscription, isChecking, error } = useSubscriptionCheck();

  if (isChecking) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-900 to-blue-900 text-white">
        <p className="text-2xl">Checking subscription status...</p>
      </div>
    );
  }

  if (error) {
    return <Navigate to="/subscription-error" />;
  }

  if (hasSubscription === false) {
    return <Navigate to="/subscription-required" />;
  }

  if (hasSubscription === true) {
    return <Navigate to="/" />;
  }

}

export default CheckSubscription;