
import React from 'react';

const STRIPE_CHECKOUT_URL = 'https://checkout.activatedhuman.earth/b/5kA7sC7aT0akaGcbIJ';

function SubscriptionRequired() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-purple-900 to-blue-900 text-white">
      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
          Subscription Required
        </h1>
        <p className="text-xl text-center mb-8 max-w-2xl">
          To access Language Learner, you'll need an active subscription. 
          Click below to start your journey!
        </p>
        <a 
          href={STRIPE_CHECKOUT_URL}
          className="bg-green-500 text-white py-2 px-6 rounded-full text-lg hover:bg-green-600 transition duration-300"
        >
          Subscribe Now
        </a>
      </main>
    </div>
  );
}

export default SubscriptionRequired;