import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const STRIPE_CHECKOUT_URL = 'https://checkout.activatedhuman.earth/b/5kA7sC7aT0akaGcbIJ';

function LoginPage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/check-subscription" />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-purple-900 to-blue-900 text-white">

      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
          Meet <span className="text-blue-400">LinguaBoost</span>, Your Language Learning Companion
        </h1>
        <p className="text-xl text-center mb-8 max-w-2xl">
          Accelerate your language learning journey and empower your future with personalized words, phrases, and concepts that are relevant to your life.
        </p>
        <div className="flex space-x-4">
          <button onClick={() => loginWithRedirect()} className="bg-blue-600 text-white py-2 px-6 rounded-full text-lg hover:bg-blue-700 transition duration-300">
            Log In
          </button>
          <a href={STRIPE_CHECKOUT_URL} className="bg-green-500 text-white py-2 px-6 rounded-full text-lg hover:bg-green-600 transition duration-300">
            Start Free Trial
          </a>
        </div>

      </main>
    </div>
  );
}

export default LoginPage;