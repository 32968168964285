import axios from 'axios';

const apiClient = axios.create({
  baseURL: "https://a.language.activatedhuman.earth/api" || '__REACT_APP_BASE_URL_API__',
});

apiClient.interceptors.request.use(config => {
  const sessionToken = localStorage.getItem('session_token');
  if (sessionToken) {
    config.headers['Authorization'] = `Bearer ${sessionToken}`;
  } else {
    console.log('No token found in localStorage.');
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default apiClient;
