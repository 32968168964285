import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, Navigate } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  console.log('PrivateRoute:', {
    path: location.pathname,
    isAuthenticated,
    isLoading
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Component {...rest} />;
}

export default PrivateRoute;