import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

function AuthHandler() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    async function handleAuthState() {
      if (!isLoading) {
        if (isAuthenticated) {
          try {
            const accessToken = await getAccessTokenSilently();
            localStorage.setItem('session_token', accessToken);
          } catch (error) {
            console.error('Error fetching access token:', error);
          }
        } else {
          navigate('/login'); // Navigate to login page if not authenticated
        }
      }
    }
    handleAuthState();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, navigate]);

  return null; // This component does not render anything
}


export default AuthHandler;
