import React from 'react';
import { useNavigate } from 'react-router-dom';

function SubscriptionError() {

  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-purple-900 to-blue-900 text-white">
      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
          Oops! Something went wrong
        </h1>
        <p className="text-xl text-center mb-8 max-w-2xl">
          We encountered an error while checking your subscription status. 
          Please try again later or contact support if the problem persists.
        </p>
        <button 
          onClick={() => navigate('/check-subscription')}
          className="bg-blue-600 text-white py-2 px-6 rounded-full text-lg hover:bg-blue-700 transition duration-300"
        >
          Try Again
        </button>
      </main>
    </div>
  );
}

export default SubscriptionError;