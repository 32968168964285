import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause, Shuffle, List, Plus, Filter, Trash2 } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import socketIOClient from "socket.io-client";
import { Button } from "../ui/button";
import { Select } from "../ui/select";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Card, CardHeader, CardContent } from "../ui/card";
import ErrorBoundary from '../ErrorBoundary';

import apiClient from '../../apiClient';


const minDelayBetweenWords = 1200; // Minimum delay between words in ms
const additionalDelay = 1200; // Additional delay after both words have been spoken


// const SOCKET_IO_ENDPOINT = "__REACT_APP_SOCKET_IO_ENDPOINT__";
 const SOCKET_IO_ENDPOINT = "https://a.language.activatedhuman.earth";
 // const SOCKET_IO_ENDPOINT = "http://localhost:8080";


function LanguageLearning() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [wordPairs, setWordPairs] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [word1, setWord1] = useState('');
  const [word2, setWord2] = useState('');
  const [language1Id, setLanguage1Id] = useState('');
  const [language2Id, setLanguage2Id] = useState('');
  const [filterLanguage1Id, setFilterLanguage1Id] = useState('');
  const [filterLanguage2Id, setFilterLanguage2Id] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShuffled, setIsShuffled] = useState(false);
  const [originalWordPairs, setOriginalWordPairs] = useState([]);
  const [error, setError] = useState(null);

  const [debugInfo, setDebugInfo] = useState('');

  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);
  const isPlayingRef = useRef(false);


  const socketRef = useRef(null);
  const isInitializingRef = useRef(false);


  const initializeSocket = useCallback(async () => {
    if (socketRef.current || isInitializingRef.current) {
      return;
    }

    isInitializingRef.current = true;

    try {
      const newSocket = socketIOClient(SOCKET_IO_ENDPOINT, {
        path: '/api/socket.io',
        withCredentials: true,
        transports: ['websocket']
      });

      newSocket.on("connect", async () => {
        console.log("Connected to server");
        try {
          const token = await getAccessTokenSilently();
          newSocket.emit('announce', { token });
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      });

      newSocket.on("authenticated", (data) => {
        if (data.status === 'success') {
          console.log("Successfully authenticated with WebSocket");
        } else {
          console.error("WebSocket authentication failed:", data.message);
        }
      });

      newSocket.on("wordPairAdded", handleWordPairAdded);
      newSocket.on("wordPairDeleted", handleWordPairDeleted);

      newSocket.on("disconnect", (reason) => {
        console.log("Disconnected from server:", reason);
        socketRef.current = null;
      });

      socketRef.current = newSocket;
    } catch (error) {
      console.error("Error initializing socket:", error);
    } finally {
      isInitializingRef.current = false;
    }
  }, [getAccessTokenSilently]);


  useEffect(() => {
    if (isAuthenticated && !socketRef.current) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [isAuthenticated, initializeSocket]);



  const fetchLanguages = async () => {
    try {
      const response = await apiClient.get('/language/languages');
      console.log('Languages response:', response);
      setLanguages(response.data);
      if (response.data.length >= 2) {
        // setLanguage1Id(response.data[0].id);
        // setLanguage2Id(response.data[1].id);
        const enLang = response.data.find(lang => lang.code === 'en');
        const ukLang = response.data.find(lang => lang.code === 'uk');
        if (enLang) setLanguage1Id(enLang.id);
        if (ukLang) setLanguage2Id(ukLang.id);
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
      console.error('Error details:', error.response?.data);
      setError('Failed to fetch languages. Please try again later.');
    }
  };

  const fetchWordPairs = async () => {
    try {
      setError(null);
      const params = {};
      if (filterLanguage1Id) params.language1_id = filterLanguage1Id;
      if (filterLanguage2Id) params.language2_id = filterLanguage2Id;
      const response = await apiClient.get('/language/word_pairs', { params });
      console.log('Word pairs response:', response);
      setOriginalWordPairs(response.data);
      setWordPairs(response.data);
    } catch (error) {
      console.error('Error fetching word pairs:', error);
      setError('Failed to fetch word pairs. Please try again later.');
    }
  };

  useEffect(() => {
    fetchWordPairs();
  }, [filterLanguage1Id, filterLanguage2Id]);

  const handleWordPairAdded = (wordPair) => {
    setWordPairs(prevWordPairs => {
      const newWordPairs = [wordPair, ...prevWordPairs];
      return isShuffled ? shuffleArray(newWordPairs) : newWordPairs;
    });
  };

  const handleWordPairDeleted = (id) => {
    setWordPairs(prevWordPairs => prevWordPairs.filter(wp => wp.id !== id));
  };

  const addWordPair = async () => {
    try {
      const response = await apiClient.post('/language/word_pairs', { word1, word2, language1_id: language1Id, language2_id: language2Id });
      setWordPairs([response.data, ...wordPairs]);
      setWord1('');
      setWord2('');
    } catch (error) {
      console.error('Error adding word pair:', error);
    }
  };

  const deleteWordPair = async (id) => {
    try {
      await apiClient.delete(`/language/word_pairs/${id}`);
      setWordPairs(wordPairs.filter(wp => wp.id !== id));
    } catch (error) {
      console.error('Error deleting word pair:', error);
    }
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    const shuffledArray = [...array];

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [shuffledArray[currentIndex], shuffledArray[randomIndex]] = 
      [shuffledArray[randomIndex], shuffledArray[currentIndex]];
    }

    return shuffledArray;
  };

  const handleShuffle = () => {
    if (isShuffled) {
      // Revert to original order
      setWordPairs([...originalWordPairs]);
      setIsShuffled(false);
    } else {
      // Shuffle the array
      setWordPairs(shuffleArray(wordPairs));
      setIsShuffled(true);
    }
    setCurrentIndex(0);
  };

  const playPause = () => {
    setIsPlaying(prev => !prev);
  };

  const playAudio = (audioRef, url) => {
    return new Promise((resolve) => {
      audioRef.current.src = url;
      audioRef.current.onloadedmetadata = () => {
        audioRef.current.play();
        audioRef.current.onended = resolve;
      };
    });
  };

  const stopAudio = () => {
    setIsPlaying(false);
    isPlayingRef.current = false;
    if (audioRef1.current) {
      audioRef1.current.pause();
      audioRef1.current.currentTime = 0;
    }
    if (audioRef2.current) {
      audioRef2.current.pause();
      audioRef2.current.currentTime = 0;
    }
  };

  const speakWords = async () => {
    if (wordPairs.length > 0 && isPlayingRef.current) {
      const wordPair = wordPairs[currentIndex];
      try {
        setDebugInfo(`Playing pair: ${wordPair.word1} - ${wordPair.word2}`);

        // Fetch English audio
        setDebugInfo(prev => `${prev}\nFetching English audio (lang_id: ${language1Id})`);
        const audioResponse1 = await apiClient.get(`/language/word_pair/${wordPair.id}/audio?lang=${language1Id}`, { responseType: 'blob' });
        const audioUrl1 = URL.createObjectURL(audioResponse1.data);

        // Play English audio
        await playAudio(audioRef1, audioUrl1);
        setDebugInfo(prev => `${prev}\nPlayed English audio`);

        // Wait for minimum delay
        await new Promise(resolve => setTimeout(resolve, minDelayBetweenWords));

        // Fetch Ukrainian audio
        setDebugInfo(prev => `${prev}\nFetching Ukrainian audio (lang_id: ${language2Id})`);
        const audioResponse2 = await apiClient.get(`/language/word_pair/${wordPair.id}/audio?lang=${language2Id}`, { responseType: 'blob' });
        const audioUrl2 = URL.createObjectURL(audioResponse2.data);

        // Play Ukrainian audio
        await playAudio(audioRef2, audioUrl2);
        setDebugInfo(prev => `${prev}\nPlayed Ukrainian audio`);

        // Wait for additional delay
        await new Promise(resolve => setTimeout(resolve, additionalDelay));

        // Move to next word pair if still playing
        if (isPlayingRef.current) {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % wordPairs.length);
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        setDebugInfo(prev => `${prev}\nError: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    if (isPlaying) {
      isPlayingRef.current = true;
      speakWords();
    } else {
      isPlayingRef.current = false;
    }

    return () => {
      isPlayingRef.current = false;
    };
  }, [currentIndex, isPlaying]);


  const nextWord = () => {
    stopAudio();
    setCurrentIndex((currentIndex + 1) % wordPairs.length);
  };

  const prevWord = () => {
    stopAudio();
    setCurrentIndex((currentIndex - 1 + wordPairs.length) % wordPairs.length);
  };

  return (
    <div className="flex flex-col h-full bg-gradient-to-br from-blue-100 to-purple-100">
      <main className="flex-1 overflow-hidden">
        <div className="h-full flex flex-col p-4 space-y-4">
          
          <ErrorBoundary>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg" role="alert">
                <strong className="font-bold">Error:</strong>
                <span className="block sm:inline ml-1">{error}</span>
              </div>
            )}
          </ErrorBoundary>
          
          <ErrorBoundary>
            <Card className="flex-shrink-0">
              <CardContent className="p-8">
                <div className="text-center mb-8">
                  {wordPairs.length > 0 ? (
                    <>
                      <h3 className="text-6xl font-bold mb-4 text-gray-800">{wordPairs[currentIndex]?.word1}</h3>
                      <p className="text-4xl text-gray-600">{wordPairs[currentIndex]?.word2}</p>
                    </>
                  ) : (
                    <div>Loading or no data available...</div>
                  )}
                </div>
                <div className="flex flex-wrap justify-center gap-4">
                  <Button onClick={prevWord} variant="outline" className="flex items-center">
                    <ChevronLeft className="w-5 h-5 mr-2" />
                    Previous
                  </Button>
                  <Button onClick={playPause} variant="default" className="flex items-center">
                    {isPlaying ? <Pause className="w-5 h-5 mr-2" /> : <Play className="w-5 h-5 mr-2" />}
                    {isPlaying ? 'Pause' : 'Play'}
                  </Button>
                  <Button onClick={handleShuffle} variant="secondary" className="flex items-center">
                    {isShuffled ? <List className="w-5 h-5 mr-2" /> : <Shuffle className="w-5 h-5 mr-2" />}
                    {isShuffled ? 'Original' : 'Shuffle'}
                  </Button>
                  <Button onClick={nextWord} variant="outline" className="flex items-center">
                    Next
                    <ChevronRight className="w-5 h-5 ml-2" />
                  </Button>
                </div>
              </CardContent>
            </Card>
          </ErrorBoundary>
          
          <ErrorBoundary>
            <Card className="flex-shrink-0">
              <CardHeader>
                <h4 className="text-xl font-semibold flex items-center">
                  <Plus className="w-5 h-5 mr-2" />
                  Add New Word Pair
                </h4>
              </CardHeader>
              <CardContent>
                {languages.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label htmlFor="word1" className="text-sm font-medium text-gray-700">English</Label>
                      <Input
                        id="word1"
                        value={word1}
                        onChange={(e) => setWord1(e.target.value)}
                        placeholder="Enter English word"
                        className="w-full"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="word2" className="text-sm font-medium text-gray-700">Ukrainian</Label>
                      <Input
                        id="word2"
                        value={word2}
                        onChange={(e) => setWord2(e.target.value)}
                        placeholder="Enter Ukrainian word"
                        className="w-full"
                      />
                    </div>
                    <div className="md:col-span-2">
                      <Button 
                        onClick={addWordPair} 
                        className="w-full bg-green-500 hover:bg-green-600 text-white"
                      >
                        <Plus className="w-4 h-4 mr-2" />
                        Add Word Pair
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>Loading languages...</div>
                )}
              </CardContent>
            </Card>
          </ErrorBoundary>
          
          <ErrorBoundary>
            <Card className="flex-1 overflow-hidden">
              <CardHeader>
                <h4 className="text-xl font-semibold">Word Pair List</h4>
              </CardHeader>
              <CardContent className="overflow-y-auto max-h-[calc(100vh-600px)]">
                {wordPairs.length > 0 ? (
                  <ul className="space-y-2">
                    {wordPairs.map((wordPair) => (
                      <li key={wordPair.id} className="flex justify-between items-center bg-gray-100 p-3 rounded-lg">
                        <span className="text-lg">{wordPair.word1} - {wordPair.word2}</span>
                        <Button onClick={() => deleteWordPair(wordPair.id)} variant="destructive" size="sm" className="flex items-center">
                          <Trash2 className="w-4 h-4 mr-1" />
                          Delete
                        </Button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="text-center py-8">
                    <span>Loading words...</span>
                  </div>
                )}
              </CardContent>
            </Card>
          </ErrorBoundary>
        </div>
      </main>
      
      <audio ref={audioRef1} className="hidden" />
      <audio ref={audioRef2} className="hidden" />
    </div>
  );
}

export default LanguageLearning;