import './App.css';
import React, { useEffect } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { usePostHog } from 'posthog-js/react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import Auth0Callback from './components/auth/Auth0Callback';
import SignUp from './components/signup/SignUp';
import LoginPage from './components/LoginPage';
import AuthHandler from './components/auth/AuthHandler';
import PrivateRoute from './components/auth/PrivateRoute';
import CheckSubscription from './components/subscription/CheckSubscription';
import SubscriptionError from './components/subscription/SubscriptionError';
import SubscriptionRequired from './components/subscription/SubscriptionRequired';

import Header from './components/Header';
import Footer from './components/Footer';
import LanguageLearning from './components/linguaboost/LanguageLearning';
import ProfileSettings from './components/linguaboost/ProfileSettings';
import WelcomePage from './components/Welcome';

const login_domain = "login.activatedhuman.earth";
const clientId = "yPwZdBlXjR3hP0zHMUVA1iaOmi0E2WoB";


function App() {

  return (
    <Auth0Provider
      domain={login_domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0-callback`,
        audience: `https://${login_domain}/api/v2/`,
        scope: "openid profile email"
      }}
    >
      <Router>
        <AppContent />
      </Router>
    </Auth0Provider>
  );
}

function AppContent() {
  const { user, isAuthenticated } = useAuth0();
  const posthog = usePostHog();

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      // Identify the user in PostHog
      posthog?.identify(user.sub, {
        email: user.email,
        name: user.name
      });

      // Example: you could also add more traits here, such as the user's subscription status or company details
      // posthog?.group('company', { companyId: user.company_id });
    }
  }, [isAuthenticated, user, posthog]);

  return (
    <div className="App font-sans text-gray-900 flex flex-col h-screen overflow-hidden">
      <Header />
      <AuthHandler />
      <main className="flex-1 overflow-hidden">
        <Routes>
          <Route path="/" element={<PrivateRoute component={LanguageLearning} />} />
          <Route path="/profile-settings" element={<PrivateRoute component={ProfileSettings} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/check-subscription" element={<CheckSubscription />} />
          <Route path="/subscription-error" element={<SubscriptionError />} />
          <Route path="/subscription-required" element={<SubscriptionRequired />} />
          <Route path="/auth0-callback" element={<Auth0Callback />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;